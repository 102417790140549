import React from 'react'

import { navigate } from 'gatsby'

/**
 */
const Page = ({ params: { language, id } }) => {
  navigate(`/${language}/${id}/addresses`)
}

export default Page
